import { clientOnly } from '@solidjs/start';
import { lazy, type Component } from 'solid-js';

export function clientLazy<T extends Component<any>>(
  f: () => Promise<{
    default: T;
  }>,
) {
  return lazy(() => Promise.resolve({ default: clientOnly(f) }));
}
